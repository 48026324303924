@use "./abstracts" as *;

@mixin priceAndCheckout() {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    margin-bottom: 20px;

    .price {
        height: 50px;
        padding-inline: 8px;
        line-height: 50px;
        font-size: x-large;
        text-align: center;
        border-radius: 8px;
        color: $green-color;
        font-weight: bolder;
        margin-right: 20px;
        background-color: rgb(170, 254, 226);
    }

    .checkout-button {
        width: 100px;
        height: 50px;
        line-height: 50px;
        font-size: large;
        text-align: center;
        background-color: rgb(75, 75, 197);
        color: white;
        cursor: pointer;
        border-radius: 8px;
    }
}

.listing-page {
    min-height: calc(100vh - 60px);
    // background-color: $white-color;

    .listing-page-content {
        width: 80%;
        margin: auto;
        padding-top: 40px;

        .listing-page-title-section {
            .title {
                font-size: xx-large;
                font-weight: bolder;
            }

            .subtitle {
                font-size: x-large;
                margin-bottom: 20px;
            }

            .price-and-checkout {
                @include priceAndCheckout();
            }
        }

        .listing-page-display {
            margin-top: 30px; // some of it is cancelled in the inner div I believe
        }

        .listing-page-details-container {
            padding-block: 20px;

            .listing-page-details {
                // padding: 20px;
                display: flex;
                flex-wrap: wrap;
                margin-left: -40px;

                .color-selectors {
                    margin-bottom: 60px;
                    margin-left: 40px;
                    max-width: 200px;

                    .color-selector {
                        &:first-child {
                            margin-bottom: 20px;
                        }

                        .color-selector-title {
                            font-size: large;
                            text-align: center;
                            margin-bottom: 10px;
                        }

                        .color-selector-color-options {
                            display: flex;
                            flex-wrap: wrap;
                            margin-left: -10px;

                            .color-option {
                                // border: 2px solid white;
                                border-radius: 4px;
                                width: 40px;
                                height: 20px;
                                cursor: pointer;
                                margin-left: 10px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }

                .color-wheel-section {
                    margin-bottom: 60px;
                    margin-left: 40px;
                    overflow: auto;

                    .color-wheel-title {
                        font-size: large;
                        text-align: center;
                        margin-bottom: 10px;

                        .color-wheel-current-title {
                            color: red;
                        }
                    }

                    .color-wheel-container {
                        // background-color: green;
                    }
                }

                .option-selector-container {
                    margin-bottom: 60px;
                    margin-left: 40px;
                    max-width: 200px;

                    .option-selector-label {
                        font-size: large;
                    }

                    .dropdowns {
                        margin-left: -10px;

                        .dropdown {
                            margin: 10px;
                        }
                    }

                    .option-selector-selections {
                        display: flex;
                        flex-wrap: wrap;
                        margin-left: -10px;

                        .option-selection {
                            // border: 2px solid black;
                            border-radius: 8px;
                            line-height: 50px;
                            text-align: center;
                            padding-inline: 12px;
                            margin: 10px;
                            cursor: pointer;
                        }
                    }
                }
            }

            .payment-container {
                margin-left: 40px;
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }
        }

        .price-and-checkout {
            @include priceAndCheckout();
        }
    }
}
