@use "../../styles/abstracts" as *;

@import url("https://fonts.googleapis.com/css?family=Gochi+Hand:regular,bold,italic&subset=latin,latin-ext");

// $primary-color: #f6d8ff;
// $secondary-color: #5ce1e6;

// $primary-color-light: #e399fa;

// @mixin miniSingleJournalPage() {
.mini-single-journal-page {
    width: 336px; // 3.5 inches
    height: 504px; // 5.25 inches

    &.mini-single-journal-page-opening {
        img {
            width: 336px;
            height: 504px;
        }
    }

    &.mini-single-journal-page-front-cover {
        // width: 336px;
        @include flexCenter(column);
        // background-color: $secondary-color;
        // background-color: $primary-color;
        font-family: Gochi Hand;
        letter-spacing: 6px;
        line-height: 50px;
        font-size: 40px;
        color: white;
        font-weight: 900;
        // -webkit-text-stroke: 8px white;
        // text-shadow: 5px 5px 0 $secondary-color;

        span {
            width: 336px; // for some reason flex from parent of front-cover hinders width
            text-align: center;
        }
    }

    &.mini-single-journal-page-back-cover {
        @include flexCenter;
        // background-color: $secondary-color;
        color: white;
        font-size: 20px;
        -webkit-text-stroke: 1.5px white;

        div {
            width: 336px;
            text-align: center;
        }
    }

    &.mini-single-journal-page-day {
        background-color: white;

        .mini-single-journal-page-day-content {
            width: 336px;

            .mini-single-journal-page-day-header {
                display: flex;
                margin-inline: 10px;
                margin-top: 15px;
                justify-content: space-between;
                align-items: center;
                height: 30px;
                line-height: 30px;
                // background-color: rgb(219, 234, 247);
                // background-color: rgb(153, 200, 242);
                // background-color: $secondary-color;
                // background-color: rgb(250, 160, 175);
                // background-color: #d281e8;
                color: white;
                padding-inline: 25px;
                border-radius: 30px 40% 30px 40%;
                // position: absolute;

                .day {
                    font-size: 20px;
                    letter-spacing: 2px;
                    font-weight: 600;
                }

                .journal-daily-icons {
                    display: flex;
                    justify-content: space-between;
                    width: 130px;
                    font-size: 22px;

                    i {
                        color: white;
                    }
                }
            }

            .rest-of-page {
                padding: 15px 20px;
                height: calc(504px - 40px - 35px); // 35 is padding top and bottom
                width: 336px;
                margin: auto;

                .prayers {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    height: 280px;
                    // background-color: rgb(221, 161, 215);
                    // background-color: rgb(221, 161, 215);
                    // background-color: rgb(250, 160, 175);
                    // background-color: $primary-color;
                    // background-color: #cb6ce6;
                    // background-color: #b8eced;

                    .fajr-row {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-inline: 5px;

                        .cell {
                            border-radius: 8px;
                            height: 40px;
                            line-height: 40px;
                            @include flexCenter;
                            width: 77.33px; // ((296 - 30 - 30 - 4) / 3)

                            &.label {
                                letter-spacing: 2px;
                            }

                            &.input {
                                background-color: white;
                                border-radius: 30px 50% 30px 30%;
                            }

                            &.checkbox {
                                width: 30px;
                                height: 30px;
                                border: 2px solid white;
                            }
                        }
                    }
                }

                .quran {
                    margin-top: 10px;

                    .quran-boxes {
                        display: flex;
                        justify-content: space-between;
                        .quran-box {
                            // background-color: rgb(250, 200, 208);
                            // background-color: rgb(250, 160, 175); // border-radius: 6px;
                            text-align: center;
                            @include flexCenter;
                            // opacity: 0.4;
                            height: calc(504px - 40px - 35px - 280px - 10px - 10px - 60px);
                            width: 92px; // (296 - 20) / 3

                            // border-radius: 40% 40% 20% 20%;

                            // .icon-left {
                            //     position: absolute;
                            //     background-color: #daf6f9;
                            //     height: 45px;
                            //     width: 45px;
                            //     border-radius: 8px;
                            //     margin-left: -19px;
                            //     margin-top: -19px;
                            //     z-index: 1;
                            //     // opacity: 0.4;
                            // }

                            .icon-right {
                                // color: #8ce8ec;
                            }
                        }
                    }

                    .quran-label {
                        margin-top: 10px;
                        text-align: center;
                        letter-spacing: 2px;
                        height: 60px;
                        line-height: 60px;
                        font-weight: 300;
                        // background-color: rgb(120, 247, 205);
                        // background-color: #efbffd;
                        // border: 2px solid $primary-color-light;
                        // color: $primary-color-light;
                        opacity: 0.4;
                        border-radius: 30px 30% 50% 40%;
                    }
                }
            }
        }
    }
}

.listing-page .listing-page-display {
    .mini-single-journal {
        .admin-button {
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: red;
            border-radius: 50%;
            margin-top: -15px;
        }

        .cover {
            .page-group {
                width: calc(360px * 2);
                // height: calc(528px * 2);
                height: calc(504px * 2 + 0.5px); // 0.5 for the margin

                .page-pair {
                    // width: calc(360px * 2);
                    width: calc(336px * 2);
                    height: 504px;
                    display: flex;

                    &:last-child {
                        margin-top: 0.5px;
                    }

                    .mini-single-journal-cover-page {
                        // width: 360px; // 3.75 inches
                        width: 336px; // 3.75 inches
                        // height: 528px; // 5.5 inches
                        height: 504px;
                        background-color: white;

                        &.front-cover {
                            @include flexCenter(column);
                            // background-color: $secondary-color;
                            // background-color: $primary-color;
                            font-family: Gochi Hand;
                            letter-spacing: 6px;
                            line-height: 50px;
                            font-size: 40px;
                            color: white;
                            font-weight: 900;
                            // -webkit-text-stroke: 8px white;
                            // text-shadow: 5px 5px 0 $secondary-color;
                        }

                        &.front-cover-inside {
                            @include flexCenter;
                        }

                        &.back-cover {
                            @include flexCenter;
                            // background-color: $secondary-color;
                            color: white;
                            font-size: 20px;
                            -webkit-text-stroke: 1.5px white;
                        }

                        &.back-cover-inside {
                            @include flexCenter;
                        }
                    }
                }
            }
        }

        .images {
            display: flex;
            // justify-content: center;
            overflow-x: auto;
            height: 530px;
            scale: 0.5;
            margin-bottom: -265px; // half of 530 // this cuts into footer when page is too short but eh
            transform: translate(-50%, -50%);
            width: 200%; // it'll be halved

            &::-webkit-scrollbar {
                height: 10px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
                border-radius: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $primary-color;
                border-radius: 8px;
            }

            .mini-single-journal-page {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .pages {
            .page-group {
                width: calc(336px * 2);
                // width: calc(336px * 1);
                height: calc(505px * 2); // the 1px extra is cuz of border under page pair
                // height: calc(504px * 1);
                .page-pair {
                    width: calc(336px * 2);
                    // width: calc(336px * 1);
                    height: 504px;
                    display: flex;

                    border: 1px solid rgb(248, 247, 247);

                    // @include miniSingleJournalPage();
                }
            }
        }
    }
}
