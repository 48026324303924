@use "./abstracts" as *;

.footer {
    background-color: $primary-color;
    color: white;
    padding-bottom: 120px;
    padding-top: 20px;
    padding-inline: 20px;
    text-align: center;
}
