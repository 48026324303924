@use "./abstracts" as *;

// .page-with-header {
//     position: fixed;
//     top: 100vh;
//     height: 0;
//     background-color: blue;

//     &.clicked {
//         width: 100vw;
//         top: 60px;
//         min-height: calc(100vh - 55px);
//         background-color: $white-color;
//         -webkit-transition: top 1s ease-out;
//         -moz-transition: top 1s ease-out;
//         -o-transition: top 1s ease-out;
//         transition: top 1s ease-out;
//     }

//     &.mobile {
//         top: 105px;
//         background-color: red;
//     }
// }

// .home-page {
//     height: calc(100vh - 50px);
//     // background-color: rgb(223, 200, 237);
//     background-color: black;
//     .home-menu-items {
//         display: flex;
//         width: 100%;
//         height: calc(100vh - 50px);
//         justify-content: space-evenly;
//         align-items: center;
//         .home-menu-item {
//             height: 300px;
//             width: 300px;
//             @include flexCenter;
//         }
//     }
// }

.not-found-page {
    position: fixed;
    // background-color: rgb(50, 101, 145);
    height: 20vh;
    width: 25vw;
    min-height: 160px;
    min-width: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    .not-found-container {
        text-align: center;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        .not-found-text {
            // color: white;
            font-weight: bold;
            padding-bottom: 20px;
        }
        .not-found-button {
            cursor: pointer;
            background-color: rgb(43, 104, 154);
            line-height: 20px;
            padding: 6px 12px;
            border-radius: 12px;
            text-align: center;
            color: white;
        }
    }
}
