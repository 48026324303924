@use "sass:math";

// $primary-color: rgb(25, 44, 32);
$primary-color: rgb(39, 25, 44);
$secondary-color: aqua;
$tertiary-color: yellow;
$white-color: rgb(248, 249, 248);
$green-color: rgb(4, 128, 4);
$purple-color: rgb(90, 34, 110);

@mixin flexCenter($direction: row) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: $direction;
}
