@use "./abstracts" as *;

body {
    margin: 0;
    // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    //     "Droid Sans", "Helvetica Neue", sans-serif;
    font-family: "Lato", serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: default;
    overflow: hidden;
}

.everything-except-header {
    overflow: auto;
    background-color: $white-color;
}

.page {
    // overflow: auto;
    // height: calc(100vh - 60px);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
    color: inherit;
    margin: 0;
}

a {
    text-decoration: none !important;
    // idk if !important is necessary
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.f {
    display: flex;
}

.tc {
    text-align: center;
}

h1 {
    font-size: 3.5em;
    font-weight: 900;
}

h2 {
    font-size: 2.5em;
    font-weight: 400;
}

h3 {
    font-size: 1.17em;
    font-weight: 400;
}

h6 {
    font-weight: 400;
}
