@use "./abstracts" as *;

.home-page {
    min-height: calc(100vh - 60px);
    // background-color: $white-color;

    .listings {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .listing-card {
            background-color: white;
            border-radius: 8px;
            // height: 260px;
            width: 200px;
            padding: 20px;
            margin: 20px;
            cursor: pointer;

            .images {
                height: calc(0.6 * 100%);
                width: 100%;
                display: inline-block;
                overflow: hidden;

                img {
                    width: 160px;
                    height: 160px;
                }
            }

            .details {
                // height: calc(0.4 * 100% - 10px);
                width: 100%;
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .title {
                    color: black;
                    font-size: large;
                    font-weight: bold;
                }

                .subtitle {
                    // height: 45px;
                    color: black;
                    // display: -webkit-box;
                    // -webkit-line-clamp: var(--line-clamp, 2);
                    // -webkit-box-orient: vertical;
                    // overflow: hidden;
                    // hyphens: auto;
                }
            }
        }
    }
}
