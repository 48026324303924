@use "./abstracts" as *;

.header-container {
    width: 100%;
    overflow: hidden;

    .header {
        min-height: calc(100vh);
        background-color: $primary-color;
        overflow: hidden;
        position: fixed;
        width: 100%;
        z-index: 1;

        &.clicked {
            height: 60px;
            min-height: 60px;
        }

        .shukr-street {
            // border: 8px solid $white-color;
            // box-sizing: border-box;
            height: 200px;
            width: 150%;
            position: fixed;
            z-index: 2;
            color: white;

            &.not-mobile {
                margin-top: calc(50vh - 100px);
                margin-left: -25%;
                -webkit-transform: rotate(-20deg);
                -ms-transform: rotate(-20deg);
                transform: rotate(-20deg);
            }

            &.mobile {
                margin-top: 5px;
                margin-left: 0;
                -webkit-transform: rotate(-0);
                -ms-transform: rotate(-0);
                transform: rotate(-0);
            }

            &.clicked {
                height: 50px;
                margin-top: 5px;
                width: 100%;
                margin-left: 0;
                -webkit-transform: rotate(0);
                -ms-transform: rotate(0);
                transform: rotate(0);
            }

            .shukr-street-text-container {
                line-height: 200px;
                height: 200px;
                word-spacing: 40px;
                white-space: nowrap;
                padding-left: 30%;
                background-color: $primary-color;
                z-index: 2;
                position: relative;

                &.clicked {
                    line-height: 50px;
                    height: 50px;
                    word-spacing: 10px;
                    text-align: left;
                    padding-left: calc(100px + 10%);
                    // border-top: 2px solid white;
                    // border-bottom: 2px solid white;
                    // box-sizing: border-box;
                    // padding-inline: 60px;
                }

                &.mobile {
                    // text-align: center;
                    padding-left: 0;
                }

                .shukr-street-text {
                    cursor: pointer;
                    display: inline-block;
                    border-block: 2px solid white;
                    box-sizing: border-box;
                    // background: linear-gradient(
                    //         to right,
                    //         $primary-color,
                    //         $purple-color,
                    //         rgb(90, 34, 110),
                    //         $purple-color,
                    //         $primary-color
                    //     ),
                    //     linear-gradient(white, $primary-color, white);
                    padding-inline: 400px;
                    margin-left: -400px;
                    line-height: 200px;
                    height: 200px;
                    text-align: center;
                    font-size: 100px;

                    &.animations {
                        -webkit-transition:
                            all 2s ease-in,
                            border 0s,
                            word-spacing 0s;
                        -moz-transition:
                            all 2s ease-in,
                            border 0s,
                            word-spacing 0s;
                        -o-transition:
                            all 2s ease-in,
                            border 0s,
                            word-spacing 0s;
                        transition:
                            all 2s ease-in,
                            border 0s,
                            word-spacing 0s;
                    }

                    &.clicked {
                        height: 50px;
                        line-height: 50px;
                        padding-inline: 0;
                        margin-inline: 0;
                        font-size: 30px;

                        &.mobile {
                            display: block;
                            font-size: 18px;
                        }

                        &.tablet {
                            font-size: 24px;
                            padding-inline: 80px;
                            margin-left: -60px;
                        }

                        &:not(.mobile) {
                            &:not(.tablet) {
                                padding-inline: 60px;
                                margin-left: -60px;
                            }
                        }
                    }
                }
            }

            .header-icons-container {
                position: absolute;
                height: 50px;
                line-height: 50px;
                font-size: 24px;
                right: 8%;
                top: 0;
                background-color: $primary-color;

                &.mobile {
                    right: 0;
                }

                .sandwich-menu {
                    height: 300px;
                    width: 50px;

                    .sandwich-icon-container {
                        width: 50px;
                        height: 50px;
                        text-align: center;
                        z-index: 3;
                        position: relative;

                        &.animations {
                            -webkit-transition: all 1s;
                            -moz-transition: all 1s;
                            -o-transition: all 1s;
                            transition: all 1s;
                        }

                        // &.mobile {
                        //     border-block: 2px solid white;
                        // }
                    }

                    .menu-dropdown-container {
                        height: 250px;
                        width: 50px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-top: -150px;

                        z-index: 0;

                        &.animations {
                            -webkit-transition: all 1s;
                            -moz-transition: all 1s;
                            -o-transition: all 1s;
                            transition: all 1s;
                        }

                        i {
                            background-color: $primary-color;
                            width: 50px;
                            height: 50px;
                            line-height: 50px;
                            text-align: center;
                            z-index: 0;
                            position: relative;
                        }

                        &.opened {
                            margin-top: 0;
                        }
                    }
                }

                .open-menu {
                    width: 160px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    z-index: 2;
                    position: relative;

                    .header-icon {
                        color: white;
                        cursor: pointer;

                        &.animations {
                            -webkit-transition: opacity 1s;
                            -moz-transition: opacity 1s;
                            -o-transition: opacity 1s;
                            transition: opacity 1s;
                        }
                    }
                }
            }
        }

        .other-streets-container {
            .other-street {
                // position: fixed;
                height: 30px;
                -webkit-transform: rotate(70deg);
                -ms-transform: rotate(70deg);
                transform: rotate(70deg);
                // z-index: 1;
                position: relative;

                &.mobile {
                    -webkit-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    transform: rotate(90deg);
                    // margin-top: 30px;
                    width: 600px;
                }
            }

            &.clicked {
                margin-top: -200px;
                // margin-left: 100px;
            }
        }
    }
}

.animations {
    -webkit-transition: all 2s ease-in;
    -moz-transition: all 2s ease-in;
    -o-transition: all 2s ease-in;
    transition: all 2s ease-in;
}
